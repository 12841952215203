/* Center the loader container */
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fix it to the screen */
  top: 0;
  left: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Optional: semi-transparent background */
  z-index: 9999; /* Ensure the loader appears above other content */
}

/* Container for the logo and the loader */
.logoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 1;
  /* animation: pulse 1.5s infinite ease-in-out; */
  /* animation: spin 2s infinite linear; */
  /* animation: bounce 1s infinite ease-in-out; */
  animation: fadeInOut 2s infinite ease-in-out;
  /* animation: wobble 1.5s infinite ease-in-out; */
  /* animation: zoom 2s infinite ease-in-out;  */
}
/* ----------Zoom animation (6)----------*/
/* @keyframes zoom {
    0%, 100% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.5); 
    }
  } */
/*----------- Wobble animation (5) -----------*/
/* @keyframes wobble {
    0%, 100% {
      transform: rotate(0deg); 
    }
    25% {
      transform: rotate(-10deg); 
    }
    75% {
      transform: rotate(10deg); 
    }
  } */
/* ------Fade In/Out animation (4)-------- */
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
}
/* ------------Bounce animation (3)------------- */
/* @keyframes bounce {
    0%, 100% {
      transform: translateY(0); 
    }
    50% {
      transform: translateY(-20px); 
    }
  } */
/*----------- Spin animation (2)----------*/
/* @keyframes spin {
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg);
  }
} */
/*---------- Pulse animation (1) -----------*/

/* @keyframes pulse {
    0%, 100% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.2); 
    }
  } */
