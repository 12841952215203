#tableListHeader th {
  background: transparent linear-gradient(180deg, #003189 0%, #001945 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 14px;
}

#tableListHeader thead {
  border: none;
}

#tableListHeader td {
  white-space: nowrap;
  font-size: 14px;
  padding: 15px 0 15px 10px;
  word-break: break-all;
  color: var(--primary-color);
}

#tableListHeader tbody tr:hover .material-icons {
  color: white !important;
}

#tableListHeader #th .textHead_th {
  opacity: 1;
  color: #fff;
  cursor: pointer !important;
}

/* -----------review css----- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.formIcon{
  background-size: unset !important;
}
.toast-success {
  background-color:#041134;
  color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;
  max-width: 400px;
  border: 5px solid #61d345
}
.toast-error{
  background-color: #041134;
  color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;
  max-width: 400px;
  border: 5px solid #c0272d
}
.toast-title {
  font-size: 16px;
  font-weight: bold;
}

.toast-action button {
  background: transparent;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.toast-viewport {
  position: fixed;
  top: 20%;
  left: 70%;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
}

.bg-review {
  position: relative;

  margin-bottom: -50px;
  background-color: var(--F6-color);
}

.review_container .contents-wraper {
  width: 7 0%;
  min-height: inherit;
  margin: 30px auto;
  text-align: center;
}

.contents-wraper .header h1 {
  position: relative;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
}

.test-comment {
  color: var(--primary-color);
  font-size: 19px;
  font-family: "nasalization";
  position: relative;

  opacity: 1;
}

.test-comment::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;

  width: 100px;
  border-bottom: 3px solid var(--secondary-color);
  border-radius: 6px;
}

.reviewRow {
  width: 100%;
  min-height: inherit;
  position: relative;
  overflow: hidden;
}

.reviewRow .reviewItem {
  width: 100%;
  position: absolute;
  padding-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.socials {
  font-size: 1px;
  color: #ba6109;
}

.reviewRow .reviewItem:not(.active) {
  top: 0;
  left: -100%;
}

.reviewRow .reviewItem img {
  width: 250px !important;
  height: 250px !important;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px;
}

.reviewRow .reviewItem h3 {
  font-size: 14px;
  color: var(--primary-color);
  padding: 7px 0px;
}

.reviewRow .reviewItem h6 {
  font-style: italic;
  font-size: 11px;
  color: var(--primary-color);
}

.reviewRow .reviewItem p {
  font-size: 18px !important;
  color: var(--primary-color);
  text-align: justify;
}

.contents-wraper .indicators {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  cursor: pointer;
}

.contents-wraper .indicators .dot {
  width: 15px;
  height: 15px;
  margin: 0px 3px;

  background-color: #aaa;

  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
}

.contents-wraper .indicators .active {
  background-color: var(--secondary-color);
}
::-webkit-scrollbar {
  width: 8px;
  height: 20px; 
}

::-webkit-scrollbar-thumb {
  background-color: #fa840e; 
  border-radius: 4px; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #cc6c0b; 
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; 
  border-radius: 4px; 
}
@media (min-width: 320px) {
  .bg-review {
    min-height: 650px;
    min-width: auto;
  }

  .bg-review::-webkit-scrollbar {
    overflow: auto;
  }

  .reviewRow .reviewItem {
    padding-left: 14px;
  }

  .reviewRow .reviewItem img {
    width: 180px !important;
    height: 180px !important;
  }

  .contents-wraper .indicators .dot {
    width: 10px;
    height: 10px;
  }

  .review_container .contents-wraper {
    width: 90%;
  }

  .contents-wraper .header h1 {
    font-size: 32px;
  }

  .reviewRow .reviewItem h3 {
    font-size: 18px;
  }

  .reviewRow .reviewItem p {
    font-size: 16px !important;
  }
  .contents-wraper .indicators{
    top: 10px !important;
  }
}

@media (min-width: 576px) {
  .bg-review {
    min-height: 550px;
  }

  .reviewRow .reviewItem {
    padding-left: 14px;
  }

  .reviewRow .reviewItem img {
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 200px;
  }

  .contents-wraper .indicators .dot {
    width: 10px;
    height: 10px;
  }

  .review_container .contents-wraper {
    width: 96%;
  }

  .contents-wraper .header h1 {
    font-size: 32px;
  }

  .reviewRow .reviewItem h3 {
    font-size: 16px;
  }

  .reviewRow .reviewItem p {
    font-size: 16px;
  }
}

@media (min-width: 641px) {
  .bg-review {
    min-width: 450px;
  }
}

@media (min-width: 768px) {
  .bg-review {
    min-height: 380px;
  }

  .reviewRow {}

  .reviewRow .reviewItem h3 {
    font-size: 20px;
  }

  .contents-wraper .indicators .dot {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: 992px) {
  .bg-review {
    min-height: 380px;
  }

  .reviewItem {
    padding-left: 50px;
  }

  .reviewRow .reviewItem h3 {
    font-size: 25px;
  }

  .contents-wraper .indicators .dot {
    width: 15px;
    height: 15px;
  }

  .reviewRow .reviewItem p {
    font-size: 22px;
  }
}

@media (min-width: 1440px) {
  .bg-review {
    min-height: 380px;
  }

  .contents-wraper .indicators .dot {
    width: 15px;
    height: 15px;
  }
}