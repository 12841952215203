* {
  box-sizing: border-box;
  /* margin: -10px; */
  padding: 0;
  margin-top: 0;
  left: 0;
}
/* body::-webkit-scrollbar {
  display: none;
} */
:root {
  --primary-color: #003189;
  --secondary-color: #fa840e;
  --pink-color: #ffb7b7;
  --brown-color: #7d4207;
  --smokey-color: #373435;
  --dark-color: #000000;
  --darkblue-color: #001945;
  --white-color: #ffffff;
  --F6-color: #f6f6f6;
  --F2-color: #f2f2f2;
  --gray-color: #dad9d5;
}

@font-face {
  font-family: "nasalization";
  src: url(/public/fonts/nasalization-rg.otf);
}
@font-face {
  font-family: "sourcesanspro";
  src: url(/public/fonts/SourceSansPro-Regular.otf);
}
body {
  font-family: "sourcesanspro" !important;
}
.app-wrapper {
  max-width: 1600px !important; /* Ensure the maximum width is enforced */
  margin: 0 auto;               /* Center the content horizontally */
  width: 100%;                  /* Ensure responsiveness */
}

/* ------------####--Title bar-----####------- */
.heading {
  font-size: 32px;
  font-family: "nasalization";
  color: var(--primary-color);
  position: relative;
  display: inline-block;
  padding-bottom: 8px;
}
.heading::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  border-bottom: 3px solid var(--secondary-color);
  border-radius: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
a {
  text-decoration: none !important;
}
/* ----------------Other heading--------- */
.other-heading {
  color: var(--primary-color);
  /* font-size: 160%; */
  font-family: "nasalization" !important;
  position: relative;
  /* padding-top: 100px; */
  padding-top: 3rem;
}
.other-heading::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  border-bottom: 3px solid var(--secondary-color);
  border-radius: 6px;
}
/* ---------Sub-heading---------- */
.sub-heading {
  color: var(--primary-color);
  /* margin-left: 32px; */
  font-family: "nasalization" !important;
  position: relative;
  padding-top: 3rem;
}
.sub-heading::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 40px;
  transform: translateX(-50%);
  width: 60px;
  border-bottom: 3px solid var(--secondary-color);
}
/* -----------------Paragraph ---------------- */
.paragraph,
.para-scroll {
  font-size: 16px;
  letter-spacing: 0.5;
  color: var(--primary-color);
  text-align: justify;
}
/* ---------para-scroll--------- */
.para-scroll {
  opacity: 1;
  overflow-y: auto;
  /* line-height: 33px; */
  margin-bottom: -17px;
  margin: auto 50px;
}
.para-scroll::-webkit-scrollbar {
  display: none;
}
/* ---------mobile-------- */
@media (min-width: 320px) {
  .para-scroll {
    font-size: 15px;
    line-height: 28px;
    height: 350px;
  }
}
/* --tab-- */
@media (min-width: 481px) {
  .para-scroll {
    font-size: 17px;
    margin: auto 16px;
    height: 180px;
  }
}
@media (min-width: 641px) {
  .para-scroll {
    height: 355px;
    line-height: 1.6;
  }
}
@media (min-width: 1024px) {
  .para-scroll {
    font-size: 17px;
    line-height: 1.7;
    height: 306px;
  }
}

@media (min-width: 1440px) {
  .para-scroll {
    font-size: 22px;
    line-height: 33px;
    height: 30px;
  }
}
/* ----------------Card Paragraph-------------- */
.card {
  /* box-shadow: 0px 3px 6px #00000029; */
  /* border-radius: 9px; */
  /* background-color: var(--white-color); */
  border: none;
}

.cards {
  background-color: var(--F6-color) !important;
}
.i {
  display: flex;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 150%;
  margin: auto 130px;
  padding: 10px;
  font-size: xx-large;
  height: 50px;
  width: 50px;
  color: var(--white-color);
  background: transparent linear-gradient(180deg, #fa840e 0%, #7d4207 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 3px 0px 6px #00000029;
  margin-top: -35px;
}
.i-b {
  display: flex;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 150%;
  margin: auto 130px;
  padding: 10px;
  font-size: xx-large;
  height: 50px;
  width: 50px;
  color: var(--white-color);
  background: transparent linear-gradient(180deg, #fa840e 0%, #7d4207 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 3px 0px 6px #00000029;
  margin-top: -35px;
}
.head {
  display: flex;
  justify-content: center;
}
.card-title {
  display: flex;
  justify-content: left;
  text-align: center;
  color: var(--primary-color) !important;
  font-size: 21px;
  font-family: "nasalization";
}
.card-text {
  font-size: 13px;
  text-align: justify;
  color: var(--primary-color);
}

/* -----------------------------------CopyRights-- (Start)------------- */
.footer_copyRight {
  background: transparent linear-gradient(180deg, #003189 0%, #001945 100%);
  opacity: 1;
}

.copy_para {
  font-weight: 600;
  font-size: 14px;
  color: var(--white-color);
}
.c_icon {
  width: 16px;
}
.terms {
  font-weight: 600 !important;
  font-size: 14px !important;
  border: none !important;
  color: var(--white-color);
  outline: none;
}

/* ------------Common ---Button (Start)---- */
.btnBanner {
  background: linear-gradient(
    180deg,
    var(--secondary-color),
    var(--brown-color)
  );
  border-radius: 4px;
  opacity: 1;
  color: var(--white-color) !important;
  margin-left: 20px;
  font-size: 12px;
  padding: 7px 16px;
}

.btnBanner:hover {
  background: var(--primary-color);
  color: var(--white-color);
}

/* ------common Button----end  */
#padding_side {
  padding: 15px 50px 20px 50px;
}
.responsive-padding {
  padding: 0px 50px !important;
}

/* --------------------Mobile-------------------------- */
@media (max-width: 768px) {
  /* ----------CopyRight ----(Start) -------- */

  .copy_para,
  .terms {
    font-size: 12px !important;
  }

  .footer_copyRight .nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }

  .footer_copyRight .nav.col-6 {
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  .footer_copyRight .copy_para {
    text-align: center;
    margin-bottom: 10px;
  }

  /* ------------Common ---Button (Start)---- */
  #padding_side {
    padding: 0px;
  }
  .responsive-padding {
    padding: 0px 20px !important;
  }
  /* ------------Common ---Button (End)---- */
}
